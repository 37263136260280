import config from '.'

export const oidcSettings = {
  authority: config.oidcAuthority,
  clientId: 'bpiece-web',
  redirectUri: config.oidcRedirectUri,
  silentRedirectUri: config.oidcSilentRedirectUri,
  post_logout_redirect_uri: config.oidcPostLogoutRedirectUri,
  responseType: 'code',
  scope: config.oidcScopes,
  automaticSilentSignin: false,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false,
  checkSessionInterval: 5000
}

import { axios, AxiosResponse } from '@oel/common'

export const getDashboardDetails = (providerProfileId: number): Promise<AxiosResponse<ProviderDashboardLocationDetails>> =>
  axios.get(`/api/bpiece/providers/${providerProfileId}/dashboardDetails`)

export const getProviderContact = (providerProfileId: number): Promise<AxiosResponse<ProviderContactDTO[]>> =>
  axios.get(`/api/bpiece/providers/${providerProfileId}/contacts`)

export const getStaff = (providerProfileId: number): Promise<AxiosResponse<ProviderStaffDTO[]>> =>
  axios.get(`/api/bpiece/providers/${providerProfileId}/staff`)

export const getClassrooms = (providerProfileId: number): Promise<AxiosResponse<ClassroomDTO[]>> =>
  axios.get(`/api/bpiece/providers/${providerProfileId}/classrooms`)

export const getDashboardStaff = (providerProfileId: number): Promise<AxiosResponse<DashboardStaffDTO>> =>
  axios.get(`/api/bpiece/providers/${providerProfileId}/assessmentDashboard`)

export const getProviderData = (): Promise<AxiosResponse<ProviderTableData[]>> =>
  axios.get('/api/bpiece/providerSearch')

export const searchForProvider = (efsId: number): Promise<AxiosResponse<ProviderTableData[]>> =>
  axios.get(`/api/bpiece/providerSearch?efsId=${efsId}`)

export const getProviderDataWithPagination = (page: number, limit: number, sortColumn: string, ascending: boolean, efsid?: string, providerName?: string, coalition?: string): Promise<AxiosResponse<ProviderTableData[]>> =>
  axios.get(`/api/bpiece/providerSearch?page=${page}&limit=${limit}&sortfield=${sortColumn}&issortasc=${ascending}&filterefsid=${efsid}&filtername=${providerName}&filtercoalition=${coalition}`)

export const getCounties = (): Promise<AxiosResponse<ProviderCounty[]>> =>
  axios.get('/api/bpiece/providers/counties')

/* eslint-disable no-unused-vars */

export enum Subjects {
    ProviderDashboard = 'ProviderDashboard',
    Assessment = 'Assessment',
    RatingSummary = 'RatingSummary',
    ProviderList = 'ProviderList'
}

export enum Actions {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete'
}

export enum QPSRoles {
    Provider = 'Provider',
    ELCAdmin = 'ELC Administrator',
    ELCReviewer = 'ELC Reviewer',
    ELCReadOnly = 'ELC Read Only',
    OELAdministrator = 'OEL Administrator',
    OELReviewer = 'OEL Reviewer',
    OELReadOnly = 'OEL Read Only'
}

type ActionRoles = Record<Actions, QPSRoles[]>

export const RolePolicies: Record<Subjects, ActionRoles> = {
  [Subjects.ProviderDashboard]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ]
  },
  [Subjects.Assessment]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ]
  },
  [Subjects.RatingSummary]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ]
  },
  [Subjects.ProviderList]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ]
  }
}

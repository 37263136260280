import { VueRouter, vuexOidcCreateRouterMiddleware, Vue } from '@oel/common'
import OidcCallback from '../components/oidc/OidcCallback.vue'
import OidcSilentRenew from '../components/oidc/OidcSilentRenew.vue'
import store from '@/store'
import { Subjects, Actions } from '@/config/policy'
import { NotFound, NotAuthorized, SignedOut } from '@oel/ui-toolkit'
import { defineRulesFor } from '@/config/ability'

const RatingSummary = () => import(/* webpackChunkName: "ratingSummary" */ '../components/rating-summary/rating-summary.vue')
const Assessment = () => import(/* webpackChunkName: "assessment" */ '../components/assessment/assessment.vue')
const ProviderDashboard = () => import(/* webpackChunkName: "providerDashboard" */ '../components/provider-dashboard/provider-dashboard.vue')
const ProviderList = () => import(/* webpackChunkName: "providerList" */ '../components/provider-list/provider-list.vue')
const OidcSignout = () => import(/* webpackChunkName: "oidcSignout" */ '../components/oidc/OidcSignout.vue')

const routes = [
  {
    path: '/provider-dashboard/:providerProfileId',
    name: 'ProviderDashboard',
    component: ProviderDashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.ProviderDashboard)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/rating-summary/:assessmentToken',
    name: 'RatingSummary',
    component: RatingSummary,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.RatingSummary)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/assessment/:assessmentToken',
    name: 'Assessment',
    component: Assessment,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.Assessment)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/provider-list',
    name: 'ProviderList',
    component: ProviderList,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.ProviderList)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidcSilentRenew',
    component: OidcSilentRenew,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/oidc-signout',
    name: 'OidcSignout',
    component: OidcSignout,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/signed-out',
    name: 'SignedOut',
    component: SignedOut,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const oidcUserKey = Object?.keys(localStorage)?.filter(k => k.startsWith('oidc.user'))[0]
  if (!(to.meta?.oidcRoute || from.meta?.oidcRoute) && oidcUserKey) {
    try {
      await store.dispatch('authenticateOidcSilent')
      const ability = store.getters.ability
      const rules = defineRulesFor(store.getters?.oidcUser?.role)
      ability.update(rules)
      store.commit('SET_ABILITY_RULES', rules)
      next()
    } catch (payload) {
      if (payload?.error === 'login_required') {
        localStorage.removeItem(oidcUserKey)
        store.dispatch('removeOidcUser')
        alert(payload)
        router.push({ name: 'SignedOut' }).catch()
      } else {
        router.push({ name: 'NotAuthorized' }).catch()
      }
    }
  } else {
    next()
  }
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store))

router.afterEach((to) => {
  if (to.name !== 'SignedOut' && to.name !== 'OidcSignout') {
    store.commit('SET_ROUTING_FINISHED')
  }
})

export { router, VueRouter }

<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import { defineRulesFor } from '@/config/ability'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions([
      'oidcSignInCallback'
    ])
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        // CASL Ability updating once user is available ------------
        const ability = store.getters.ability
        const rules = defineRulesFor(store.getters?.oidcUser?.role)
        ability.update(rules)
        this.$store.commit('SET_ABILITY_RULES', rules)
        // ---------------------------------------------------------
        this.$router.push({ path: redirectPath }).catch(() => { /* vue-router will throw error if this route is redirected by route guard */ })
      })
      .catch(() => {
        this.$router.push({ name: 'SignedOut' }).catch(() => { /* vue-router will throw error if this route is redirected by route guard */ })
      })
  }
}
</script>

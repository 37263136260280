export default {
  apiUrl: 'http://localhost:5001/',
  oidcAuthority: 'http://localhost:5000/',
  oidcRedirectUri: 'http://localhost:8082/oidc-callback',
  oidcSilentRedirectUri: 'http://localhost:8082/oidc-silent-renew',
  oidcPostLogoutRedirectUri: 'http://localhost:8082/oidc-signout',
  oidcScopes: 'openid profile bpieceScope roles',
  vuexEncryptionKey: 'GYc&H7U&8(Tv8D73SgjA+n]"z(Ax,*GF$]DFA',
  bpieceUrl: 'http://localhost:8082/',
  pyramidUrl: 'http://localhost:8084/',
  fsu10Url: 'http://localhost:8080/',
  qpsUrl: 'http://localhost:8086/',
  srpaUrl: 'http://localhost:50615/',
  qpsSignoutRedirectUrl: 'http://localhost:8086/oidc-signout-redirect',
  showEnvironmentBanner: true,
  environmentDescription: 'Development'
}

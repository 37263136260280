import { Vue, Vuex, vuexOidcCreateStoreModule } from '@oel/common'
import users from './modules/users'
import { getField, updateField } from 'vuex-map-fields'
import { Locales } from '@/i18n/locales'
import { defaultLocale } from '@/i18n'
import { oidcSettings } from '../config/oidc'
import { Ability } from '@casl/ability'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import config from '@/config'
import { router } from '@/router'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// encryption for vuex persisted state
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: config.vuexEncryptionKey
})

// STATE
const state = {
  locale: defaultLocale,
  bpiece_rules: '',
  routingToggle: false,
  isSaved: true
}

// MUTATIONS
const mutations = {
  updateField,
  SET_IS_SAVED: (currentState, payload) => {
    currentState.isSaved = payload
  },
  SET_LOCALE: (state, payload: Locales) => {
    state.locale = payload
  },
  SET_ABILITY_RULES: (state, rules) => {
    state.bpiece_rules = rules
  },
  SET_ROUTING_FINISHED: (state) => { state.routingToggle = !state.routingToggle }
}

// ACTIONS
const actions = {
  changeLocale({ commit }, newLocale) {
    commit('SET_LOCALE', newLocale)
  },
  changeIsSaved({ commit }, value) {
    commit('SET_IS_SAVED', value)
  }
}

// GETTERS
const getters = {
  getField,
  ability() {
    return new Ability()
  },
  getCaslRules: (state) => {
    return state.bpiece_rules
  },
  routeFinished: (state) => state.routingToggle
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['bpiece_rules'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  modules: {
    users,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        dispatchEventsOnWindow: true
      },
      {
        // userLoaded: (user) => console.log('OIDC user is loaded:', user),
        // userUnloaded: () => console.log('OIDC user is unloaded'),
        // oidcError: (payload) => console.log(`An OIDC error occured at ${payload?.context}:`, payload?.error),
        // userSignedOut: () => console.log('BPIECE: User signed out.'),
        // accessTokenExpiring: () => console.log('Access token will expire'),
        // accessTokenExpired: () => console.log('Access token did expire'),
        // silentRenewError: () => console.log('silentRenewError: silent renew failed'),
        automaticSilentRenewError: (payload) => payload?.error === 'login_required' && router.currentRoute.name !== 'SignedOut' ? router.push({ name: 'SignedOut' }) : null
      }
    )
  },
  state,
  getters,
  mutations,
  actions,
  strict: debug
})

export default store

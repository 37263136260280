export default {
  apiUrl: 'https://gateway-api-qps.floridaearlylearning.com/',
  oidcAuthority: 'https://auth-qps.floridaearlylearning.com/',
  oidcRedirectUri: 'https://bpiece-qps.floridaearlylearning.com/oidc-callback',
  oidcSilentRedirectUri: 'https://bpiece-qps.floridaearlylearning.com/oidc-silent-renew',
  oidcPostLogoutRedirectUri: 'https://bpiece-qps.floridaearlylearning.com/oidc-signout',
  oidcScopes: 'openid profile bpieceScope roles',
  vuexEncryptionKey: 'GYc&H7U&8(Tv8D73SgjA+n]"z(Ax,*GF$]DFA',
  bpieceUrl: 'https://bpiece-qps.floridaearlylearning.com/',
  pyramidUrl: 'https://pyramid-qps.floridaearlylearning.com/',
  fsu10Url: 'https://10components-qps.floridaearlylearning.com/',
  qpsUrl: 'https://portal-qps.floridaearlylearning.com/',
  srpaUrl: 'https://qps.floridaearlylearning.com/',
  qpsSignoutRedirectUrl: 'https://portal-qps.floridaearlylearning.com/oidc-signout-redirect',
  showEnvironmentBanner: false,
  environmentDescription: ''
}

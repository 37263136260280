import { Locales } from './locales'

import en from './en.json'
import sp from './sp.json'

export const messages = {
  [Locales.EN]: en,
  [Locales.SP]: sp
}

export const defaultLocale = Locales.EN

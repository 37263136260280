import { configureOelVue, vuetify, Vue } from '@oel/common'
import App from './App.vue'
import { router } from './router/index'
import store from './store/index'
import config from './config'
import './css/site.less'
import VueI18n from 'vue-i18n'
import { setAxiosRequestInterceptor, setAxiosSrpaRequestInterceptor } from './helpers/axios'
import { abilitiesPlugin } from '@casl/vue'
import { UserManager } from 'oidc-client'
import { oidcSettings } from '@/config/oidc'
import { messages, defaultLocale } from '@/i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
})

configureOelVue(
  config,
  config.apiUrl,
  config.srpaUrl,
  '#682f93'
)

// expose the config variables to the application components
Vue.prototype.appConfig = config

Vue.use(abilitiesPlugin, store.getters.ability)

// this extends the functionality of oel-common axios config and adds the JWT to the authorization header
setAxiosRequestInterceptor()
setAxiosSrpaRequestInterceptor()

let app: any = null

Vue.prototype.$confirm = (message, title) => {
  const promise = new Promise((resolve, reject) => {
    try {
      // DISCUSS this really should not have a reference to 'app' here. Consider using vuex or some other alternative instead
      app.confirmTitle = title == null ? 'Confirm' : title
      app.confirmMessage = message
      app.confirmCallback = (val) => {
        resolve(val)
      }
    } catch (err) {
      reject(err)
    }
  })
  app.ConfirmDialog = true
  return promise
}

app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  ...App,
  beforeCreate() {
    // this exists to read the users casl rules back from localstorage if the page is refreshed
    const ability = store.getters.ability
    const rules = store.getters.getCaslRules
    ability.update(rules)
  }
} as any)

app.$mount('#app')

export default {
  apiUrl: 'https://gateway-api.oel.local/',
  oidcAuthority: 'https://auth-app.oel.local/',
  oidcRedirectUri: 'https://bpiece-app.oel.local/oidc-callback',
  oidcSilentRedirectUri: 'https://bpiece-app.oel.local/oidc-silent-renew',
  oidcPostLogoutRedirectUri: 'https://bpiece-app.oel.local/oidc-signout',
  oidcScopes: 'openid profile bpieceScope roles',
  vuexEncryptionKey: 'GYc&H7U&8(Tv8D73SgjA+n]"z(Ax,*GF$]DFA',
  bpieceUrl: 'https://bpiece-app.oel.local/',
  pyramidUrl: 'https://pyramid-app.oel.local/',
  fsu10Url: 'https://fsu10-app.oel.local/',
  qpsUrl: 'https://qps-app.oel.local/',
  srpaUrl: 'https://qps-appstest.oel.local/',
  qpsSignoutRedirectUrl: 'https://qps-app.oel.local/oidc-signout-redirect',
  showEnvironmentBanner: true,
  environmentDescription: 'Test'
}
